import React from 'react';

import { graphql } from 'gatsby';

const ServicosTemplate = (props) => {

  return (
    <>
      <h1>serviços</h1>
    </>
  );
};

export const query = graphql`
  query Servicos($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
    }
  }
`;

export default ServicosTemplate;
